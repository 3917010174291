import { EntityType } from "@/types/Entity"

const DIRECTORY_ALIAS = "dir"
const YOUTUBE_ALIAS = "youtube"

export function isDirectory(entity: EntityType): boolean {
    return entity.type.alias === DIRECTORY_ALIAS
}

export function isFile(entity: EntityType): boolean {
    return !isDirectory(entity)
}

export function isYoutube(entity: EntityType): boolean {
    return entity.type.alias === YOUTUBE_ALIAS
}
