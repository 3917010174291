
import { Vue, Options, Prop } from "vue-property-decorator"
import { PropType } from "vue"
import Entity from "@/components/utils/Entity/Entity.vue"
import { EntityType } from "@/types/Entity"
import { isDirectory } from "@/utils/check-entity-type"

@Options({
    components: {
        Entity,
    },
})
export default class EntityDirectory extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        validator: isDirectory,
        required: true,
    })
    readonly entity!: EntityType

    get isEmpty(): boolean {
        return this.entity.children?.length === 0
    }
}
