import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityFabric = _resolveComponent("EntityFabric")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(_ctx.$style.entityLink),
    to: _ctx.generateLink(_ctx.entity)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_EntityFabric, { entity: _ctx.entity }, null, 8, ["entity"])
    ]),
    _: 1
  }, 8, ["class", "to"]))
}