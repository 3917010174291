
import { Vue, Options } from "vue-property-decorator"
import ToolbarItem from "@/components/utils/Toolbar/Items/ToolbarItem.vue"

@Options({
    components: {
        ToolbarItem,
    },
})
export default class ToolbarItemBack extends Vue {
    back(): void {
        const prevUrl: string | null = window.history.state.back
        const canBack = prevUrl !== null && !prevUrl.includes("/login")

        if (canBack) {
            this.$router.back()
        }
    }
}
