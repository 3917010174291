import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.app)
  }, [
    _createVNode(_component_Toolbar, {
      class: _normalizeClass(_ctx.$style.toolbar)
    }, null, 8, ["class"]),
    _createVNode(_component_Container, {
      class: _normalizeClass(_ctx.$style.container)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}