
import { Vue, Options, Inject } from "vue-property-decorator"
import FailureBanner from "@/components/utils/FailureBanner.vue"
import { UserType } from "@/types/User"

@Options({
    components: {
        FailureBanner,
    },
})
export default class Login extends Vue {
    @Inject()
    user!: UserType

    isForbidden: boolean = false

    // noinspection JSUnusedGlobalSymbols
    async beforeCreate(): Promise<void> {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const { otp_token: token } = Object.fromEntries(urlSearchParams.entries())

        const forbid = () => {
            this.isForbidden = true
            window.parent.postMessage("login", "*")
        }

        if (token) {
            try {
                await this.$axios.get(`https://portal.askom.io/api/otp/get_user/${token}`)

                this.user.signedIn = true
                this.user.token = token as string

                await this.$router.push({ name: "index" })
            } catch (e) {
                forbid()
            }

            return
        }

        forbid()
    }
}
