import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { UserType } from "@/types/User"

// Корневой файл стилей
import "@/assets/scss/app.scss"

// Плагины (из папки Plugins)
import globalProperties from "@/plugins/global-properties"

// Экземпляр приложения
const app = createApp(App)

// Пользователь
const user: UserType = {
    signedIn: false,
    token: null,
}

app.provide("user", user)

// Обработка переходов между страницами
router.beforeEach((to, from, next) => {
    if (to.meta.auth && !user.signedIn) {
        next({ name: "login" })
    } else {
        next()
    }
})

app.use(router).use(globalProperties).mount("#app")
