import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import IndexPage from "@/views/Index.vue"
import FilePage from "@/views/File.vue"
import LoginPage from "@/views/Login.vue"

const routes: RouteRecordRaw[] = [
    {
        path: "/:id?",
        name: "index",
        component: IndexPage,
        meta: {
            auth: true,
        },
    },
    {
        path: "/file/:id?",
        name: "file",
        component: FilePage,
        meta: {
            auth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: LoginPage,
        meta: {
            auth: false,
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
