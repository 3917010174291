
import { Vue, Options, Prop } from "vue-property-decorator"
import { PropType } from "vue"
import EntityYoutube from "@/components/utils/Entity/EntityYoutube.vue"
import EntityDirectory from "@/components/utils/Entity/EntityDirectory.vue"
import { isDirectory } from "@/utils/check-entity-type"
import { EntityType } from "@/types/Entity"

@Options({
    components: {
        EntityYoutube,
        EntityDirectory,
    },
})
export default class EntityFabric extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        required: true,
    })
    readonly entity!: EntityType

    get component(): string {
        if (isDirectory(this.entity)) {
            return "EntityDirectory"
        }

        return "EntityYoutube"
    }
}
