
import { Vue, Prop } from "vue-property-decorator"
import { PropType } from "vue"
import { EntityType } from "@/types/Entity"
import { isYoutube } from "@/utils/check-entity-type"
import { getEntitySpecificationByKey } from "@/utils/get-entity-specification-by-key"
import { getYoutubeVideoId } from "@/utils/get-youtube-video-id"

export default class EntityViewYoutube extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        validator: isYoutube,
        required: true,
    })
    readonly entity!: EntityType

    get videoId(): string {
        const specification = getEntitySpecificationByKey(this.entity, "url")
        return getYoutubeVideoId(specification?.value || "")
    }

    get url(): string {
        return this.isInvalid ? "" : `https://www.youtube.com/embed/${this.videoId}`
    }

    get isInvalid(): boolean {
        return !this.videoId
    }
}
