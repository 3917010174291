
import { Vue, Options, Prop } from "vue-property-decorator"
import EntityFabric from "@/components/utils/Entity/EntityFabric.vue"
import { PropType } from "vue"
import { EntityType } from "@/types/Entity"
import generateLinkForEntity from "@/utils/generate-link-for-entity"

@Options({
    components: {
        EntityFabric,
    },
})
export default class EntityLink extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        required: true,
    })
    readonly entity!: EntityType

    generateLink = generateLinkForEntity
}
