
import { Vue, Options, Prop } from "vue-property-decorator"
import { PropType } from "vue"
import EntityLink from "@/components/utils/EntityLink.vue"
import { EntityType } from "@/types/Entity"

@Options({
    components: {
        EntityLink,
    },
})
export default class EntityList extends Vue {
    @Prop({ type: Array as PropType<EntityType[]> })
    readonly entities!: EntityType[]
}
