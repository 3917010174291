import axios from "axios"
import { EntityType } from "@/types/Entity"

type QueryParams = {
    children?: boolean
    parent?: boolean
    parents_chain?: boolean
    children_for_parent?: boolean
}

export default class EntityService {
    public async fetchTopLevel(): Promise<EntityType[]> {
        const url = `${process.env.VUE_APP_API}/entities`
        const { data: entities } = await axios.get<EntityType[]>(url)

        return entities
    }

    public async fetchById(id: number, params?: QueryParams): Promise<EntityType> {
        const url = `${process.env.VUE_APP_API}/entities/${id}`
        const { data: entity } = await axios.get<EntityType>(url, { params })

        return entity
    }
}
