import { App } from "vue"
import axios from "axios"
import services from "@/services"

export default {
    install(app: App): void {
        app.config.globalProperties.$axios = axios
        app.config.globalProperties.$services = services
    },
}
