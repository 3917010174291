import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.entityViewYoutube, { [_ctx.$style.invalid]: _ctx.isInvalid }])
  }, [
    _createElementVNode("iframe", {
      class: _normalizeClass(_ctx.$style.core),
      src: _ctx.url,
      title: "YouTube video player",
      frameborder: "0",
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen: ""
    }, null, 10, _hoisted_1)
  ], 2))
}