
import { Vue, Options, Prop } from "vue-property-decorator"
import { PropType } from "vue"
import EntityViewYoutube from "@/components/utils/EntityView/EntityViewYoutube.vue"
import { EntityType } from "@/types/Entity"

@Options({
    components: {
        EntityViewYoutube,
    },
})
export default class EntityView extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        required: true,
    })
    readonly entity!: EntityType

    get component(): string {
        return "EntityViewYoutube"
    }
}
