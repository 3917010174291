
import { Vue, Options, Watch } from "vue-property-decorator"
import { RouteLocationNormalized } from "vue-router"
import Spinner from "@/components/utils/Spinner.vue"
import FailureBanner from "@/components/utils/FailureBanner.vue"
import EntityList from "@/components/utils/EntityList.vue"
import { EntityType } from "@/types/Entity"
import { isDirectory, isFile } from "@/utils/check-entity-type"

@Options({
    components: {
        Spinner,
        FailureBanner,
        EntityList,
    },
})
export default class Index extends Vue {
    directory: EntityType | null = null
    children: EntityType[] = []

    loading: boolean = true

    get childrenDirectories(): EntityType[] {
        return this.children.filter(isDirectory)
    }

    get childrenFiles(): EntityType[] {
        return this.children.filter(isFile)
    }

    get childrenSorted(): EntityType[] {
        return [...this.childrenDirectories, ...this.childrenFiles]
    }

    get isNotFound(): boolean {
        return this.childrenSorted.length === 0
    }

    async replaceDirectory(id: number): Promise<void> {
        if (id) {
            this.directory = await this.$services.entities.fetchById(id, {
                children: true,
                parents_chain: true,
            })
        } else {
            this.directory = null
        }
    }

    async replaceChildren(): Promise<void> {
        if (this.directory) {
            this.children = this.directory.children!
        } else {
            this.children = await this.$services.entities.fetchTopLevel()
        }
    }

    @Watch("$route", { immediate: true })
    async onRouteUpdate(to: RouteLocationNormalized): Promise<void> {
        const id: number = Number(to.params.id)

        try {
            this.loading = true

            await this.replaceDirectory(id)
            await this.replaceChildren()
        } catch (e) {
            console.error(e)
        } finally {
            this.loading = false
        }
    }
}
