
import { Prop, Vue } from "vue-property-decorator"
import { PropType } from "vue"
import { EntityType } from "@/types/Entity"

export default class Entity extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        required: true,
    })
    readonly entity!: EntityType
}
