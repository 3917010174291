import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Entity = _resolveComponent("Entity")!

  return (_openBlock(), _createBlock(_component_Entity, {
    class: _normalizeClass(_ctx.$style.entityYoutube),
    entity: _ctx.entity
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.preview),
        style: _normalizeStyle({ backgroundImage: `url(${_ctx.previewUrl})` })
      }, null, 6)
    ]),
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.entity.name), 1)
    ]),
    _: 1
  }, 8, ["class", "entity"]))
}