import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), {
    class: _normalizeClass(_ctx.$style.toolbarItem)
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.icon),
            style: _normalizeStyle(`background-image: url(${_ctx.icon})`)
          }, null, 6))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.text)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["class"]))
}