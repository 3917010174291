import { EntityType } from "@/types/Entity"
import { isFile } from "@/utils/check-entity-type"

type EntityLink = {
    name: string
    params: unknown
}

export default function generateLinkForEntity(entity: EntityType): EntityLink {
    const name: string = isFile(entity) ? "file" : "index"
    const params: unknown = { id: entity.id }

    return { name, params }
}
