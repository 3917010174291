import EntityService from "@/services/EntityService"

export class Services {
    public readonly entities: EntityService

    public constructor() {
        this.entities = new EntityService()
    }
}

const services: Services = new Services()

export default services
