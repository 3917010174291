
import { Vue, Options } from "vue-property-decorator"
import Toolbar from "@/components/utils/Toolbar/Toolbar.vue"
import Container from "@/components/utils/Container.vue"

@Options({
    components: {
        Toolbar,
        Container,
    },
})
export default class App extends Vue {}
