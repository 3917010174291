
import { Vue, Options, Watch } from "vue-property-decorator"
import Spinner from "@/components/utils/Spinner.vue"
import FailureBanner from "@/components/utils/FailureBanner.vue"
import EntityView from "@/components/utils/EntityView/EntityView.vue"
import EntityList from "@/components/utils/EntityList.vue"
import EntityLink from "@/components/utils/EntityLink.vue"
import { EntityType } from "@/types/Entity"
import { RouteLocationNormalized } from "vue-router"
import { isDirectory, isFile } from "@/utils/check-entity-type"

@Options({
    components: {
        Spinner,
        FailureBanner,
        EntityView,
        EntityList,
        EntityLink,
    },
})
export default class File extends Vue {
    file!: EntityType
    neighbours: EntityType[] = []

    loading: boolean = true

    get neighbourFiles(): EntityType[] {
        return this.neighbours.filter(isFile)
    }

    get isNotFound(): boolean {
        return !this.file || isDirectory(this.file)
    }

    async replaceFile(id: number): Promise<void> {
        this.file = await this.$services.entities.fetchById(id, { children_for_parent: true })
    }

    async replaceNeighbours(): Promise<void> {
        if (this.file.parent) {
            this.neighbours = this.file.parent.children!
        } else {
            this.neighbours = await this.$services.entities.fetchTopLevel()
        }
    }

    @Watch("$route", { immediate: true })
    async onRouteUpdate(to: RouteLocationNormalized): Promise<void> {
        const id: number = Number(to.params.id)

        try {
            this.loading = true

            await this.replaceFile(id)
            await this.replaceNeighbours()
        } catch (e) {
            console.error(e)
        } finally {
            this.loading = false
        }
    }
}
