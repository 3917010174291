
import { Vue, Prop } from "vue-property-decorator"

export default class ToolbarItem extends Vue {
    @Prop({ type: String, default: "div" })
    readonly as!: string

    @Prop({ type: String, default: null })
    readonly icon!: string | null
}
