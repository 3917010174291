
import { Vue, Options } from "vue-property-decorator"
import Container from "@/components/utils/Container.vue"
import ToolbarItemHome from "@/components/utils/Toolbar/Items/ToolbarItemHome.vue"
import ToolbarItemBack from "@/components/utils/Toolbar/Items/ToolbarItemBack.vue"
import ToolbarItemRefresh from "@/components/utils/Toolbar/Items/ToolbarItemRefresh.vue"

@Options({
    components: {
        Container,
        ToolbarItemHome,
        ToolbarItemBack,
        ToolbarItemRefresh,
    },
})
export default class Toolbar extends Vue {}
