
import { Vue, Options } from "vue-property-decorator"
import ToolbarItem from "@/components/utils/Toolbar/Items/ToolbarItem.vue"

@Options({
    components: {
        ToolbarItem,
    },
})
export default class ToolbarItemRefresh extends Vue {
    refresh(): void {
        this.$router.go(0)
    }
}
