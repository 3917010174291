
import { Vue, Options } from "vue-property-decorator"
import ToolbarItem from "@/components/utils/Toolbar/Items/ToolbarItem.vue"

@Options({
    components: {
        ToolbarItem,
    },
})
export default class ToolbarItemHome extends Vue {}
