import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Entity = _resolveComponent("Entity")!

  return (_openBlock(), _createBlock(_component_Entity, {
    class: _normalizeClass(_ctx.$style.entityDirectory),
    entity: _ctx.entity
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.icon, { [_ctx.$style.empty]: _ctx.isEmpty }])
      }, null, 2),
      (_ctx.entity.children)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.count)
          }, _toDisplayString(_ctx.entity.children.length) + " эл.", 3))
        : _createCommentVNode("", true)
    ]),
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.entity.name), 1)
    ]),
    _: 1
  }, 8, ["class", "entity"]))
}