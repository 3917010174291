import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLink = _resolveComponent("EntityLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.entityList)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
      return _renderSlot(_ctx.$slots, "default", { entity: entity }, () => [
        _createVNode(_component_EntityLink, {
          entity: entity,
          key: entity.id
        }, null, 8, ["entity"])
      ])
    }), 256))
  ], 2))
}