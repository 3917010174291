
import { Vue, Options, Prop } from "vue-property-decorator"
import { PropType } from "vue"
import Entity from "@/components/utils/Entity/Entity.vue"
import { EntityType } from "@/types/Entity"
import { isYoutube } from "@/utils/check-entity-type"
import { getEntitySpecificationByKey } from "@/utils/get-entity-specification-by-key"
import { getYoutubeVideoId } from "@/utils/get-youtube-video-id"

@Options({
    components: {
        Entity,
    },
})
export default class EntityDirectory extends Vue {
    @Prop({
        type: Object as PropType<EntityType>,
        validator: isYoutube,
        required: true,
    })
    readonly entity!: EntityType

    get previewUrl(): string {
        const specification = getEntitySpecificationByKey(this.entity, "url")
        const videoId = getYoutubeVideoId(specification?.value || "")

        return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`
    }
}
