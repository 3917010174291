import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.entity),
    title: _ctx.entity.name
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.title)
    }, [
      _renderSlot(_ctx.$slots, "title")
    ], 2)
  ], 10, _hoisted_1))
}